import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '20px 0',
      }}
    >
      <Typography>&copy; 2024 Jazzinator. All rights reserved.</Typography>
    </Box>
  );
}

export default Footer;

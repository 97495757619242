
import Track from './Track'; // Assumendo che Track sia un altro modulo

class Song {
    constructor(songName, context) {
        // the web audio context
        this.audioContext = context;
        // name of the song
        this.name = songName;
        // url of this song
        this.url = "track/" + songName;
        // list of tracks in that song
        this.tracks = [];
        // master volume of this song

        this.elapsedTimeSinceStart = 0;

        // song is paused ?
        this.paused = true;

        // song is muted ?
        this.muted = false;

        // Recording mix mode ?
        this.recording = false;

        // loop we start again at the beginning of the loop
        this.loopMode = false;
        this.loopStartTime = 0;
        this.loopEndTime = 0;

        // record mix ?
        this.recordMixMode = false;

        // list of decoded audio buffers
        this.decodedAudioBuffers = [];

        // The web audio graph nodes
        // Master volume
        this.masterVolumeNode = this.audioContext.createGain();
        this.trackVolumeNodes = [];
        this.analyserNode = this.audioContext.createAnalyser();
        // For saving the mix to a .wav file, it's better to build this node only once and reuse it.
        
        this.recIndex = 0; // for generating name of exported mix

        // Origin of the web audio graph, useful for start/stop/pause
        this.sampleNodes = [];
    }

    addTrack(instrument) {
        this.tracks.push(new Track(this.name, instrument));
    }

    buildGraph() {
        if (this.sampleNodes.length > 0) {
            console.warn("Graph already built, skipping.");
            return;
        }
    
        const sources = [];
        for (let i = 0; i < this.decodedAudioBuffers.length; i++) {
            const sample = this.decodedAudioBuffers[i];
    
            // Crea il nodo AudioBufferSourceNode
            const source = this.audioContext.createBufferSource();
            source.buffer = sample;
    
            // Crea il GainNode per la traccia
            const volumeNode = this.audioContext.createGain();
            volumeNode.gain.value = this.tracks[i]?.muted ? 0 : this.tracks[i]?.volume;
    
            // Collega i nodi
            source.connect(volumeNode);
            volumeNode.connect(this.masterVolumeNode);
    
            sources.push(source);
        }
    
        // Collega il master volume all'analyser e all'uscita audio
        this.masterVolumeNode.connect(this.analyserNode);
        this.analyserNode.connect(this.audioContext.destination);
    
        this.sampleNodes = sources; // Salva i nodi per il controllo
    }
    
    

    play(startTime = 0) {
        if (!this.paused) {
            console.warn("Cannot play: song is already playing.");
            return;
        }
    
        // Ricrea i nodi solo se necessario
        if (this.sampleNodes.length === 0) {
            this.buildGraph();
        }
    
        this.setTrackVolumesDependingOnMuteSoloStatus();
    
        // Imposta il tempo di inizio
        this.startTime = this.audioContext.currentTime;
    
        // Avvia ogni traccia dal punto specificato
        this.sampleNodes.forEach((s, index) => {
            try {
                s.start(0, startTime);
            } catch (error) {
                console.error(`Error starting sample node for track ${index}:`, error);
            }
        });
    
        this.paused = false;
    }
    
    
    
    pause() {
        if (!this.paused) {
            // Calcola il tempo trascorso dalla partenza effettiva
            this.elapsedTimeSinceStart += this.audioContext.currentTime - this.startTime;
    
            // Ferma i nodi
            this.sampleNodes.forEach((s, index) => {
                try {
                    s.stop(); // Ferma il nodo
                } catch (error) {
                    console.warn(`Error stopping sample node during pause for track ${index}:`, error);
                }
            });
            this.paused = true;
        } else {
            // Riprendi dal punto in cui è stato interrotto
            this.startTime = this.audioContext.currentTime; // Registra il nuovo startTime
            this.sampleNodes = []; // Pulisci i nodi precedenti
            this.buildGraph(); // Ricrea i nodi per riprendere
            this.sampleNodes.forEach((s, index) => {
                try {
                    s.start(0, this.elapsedTimeSinceStart); // Riprendi da dove era stato interrotto
                } catch (error) {
                    console.error(`Error starting sample node for track ${index}:`, error);
                }
            });
            this.paused = false;
        }
    }
    
    
    
    
    stop() {
        if (this.sampleNodes.length === 0) {
            console.warn("No nodes to stop.");
            return;
        }
    
        this.sampleNodes.forEach((s) => {
            try {
                s.stop(0);
            } catch (error) {
                console.warn("Error stopping sample node:", error);
            }
        });
    
        this.sampleNodes = []; // Svuota i nodi
        this.elapsedTimeSinceStart = 0; // Resetta il tempo
        this.paused = true;
    
        if (this.recordMixMode) {
            this.toggleRecording();
        }
    }
    


    setVolume(value) {
        this.volume = value;
        this.masterVolumeNode.gain.value = value;
    }

    setVolumeOfTrack(value, trackNumber) {
        if (this.trackVolumeNodes[trackNumber]) {
            this.trackVolumeNodes[trackNumber].gain.value = value;
            this.tracks[trackNumber].volume = value;
        }
    }

    getUrlsOfTracks() {
        return this.tracks.map((track) => track.url);
    }

    getDuration() {
        return this.decodedAudioBuffers[0]?.duration || 0;
    }

    getNbTracks() {
        return this.tracks.length;
    }

    setDecodedAudioBuffers(buffers) {
        this.decodedAudioBuffers = buffers;

        for (let i = 0; i < this.tracks.length; i++) {
            this.tracks[i].decodedBuffer = this.decodedAudioBuffers[i];
        }
    }

    toggleLoopMode() {
        this.loopMode = !this.loopMode;
    }

    toggleMute() {
        this.muted = !this.muted;
        this.masterVolumeNode.gain.value = this.muted ? 0 : this.volume;
    }

    setTrackVolumesDependingOnMuteSoloStatus() {
        const hasSolo = this.tracks.some((track) => track?.solo);
    
        this.trackVolumeNodes.forEach((node, i) => {
            // Verifica che la traccia esista
            const track = this.tracks[i];
            if (!track) {
                console.warn(`Track at index ${i} is undefined.`);
                node.gain.value = 0; // Imposta il volume a 0 per sicurezza
                return;
            }
    
            // Se c'è almeno una traccia in solo, gestisci di conseguenza
            if (hasSolo) {
                node.gain.value = track.solo ? track.volume : 0;
            } else {
                node.gain.value = track.muted ? 0 : track.volume;
            }
        });
    }
    


    setGainNode(gainNode) {
        this.masterVolumeNode = gainNode;
        if (isFinite(this.volume)) {
            this.masterVolumeNode.gain.value = this.volume;
          } else {
            console.warn('Invalid volume value:', this.volume);
            this.masterVolumeNode.gain.value = 1;  // Imposta il volume a un valore predefinito valido
          }        // Ricollega tutti i nodi di volume dei track al master volume node
        this.trackVolumeNodes.forEach((volumeNode) => {
            volumeNode.connect(this.masterVolumeNode);
        });
        // Collega il master volume node all'analyzer e quindi al destination
        if (this.masterVolumeNode.context !== this.analyserNode.context) {
            console.error("I nodi appartengono a contesti audio differenti!");
          } else {
            this.masterVolumeNode.connect(this.analyserNode);
          }
        this.analyserNode.connect(this.audioContext.destination);
    }


}

export default Song;

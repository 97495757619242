import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function Header({ userEmail, onLogout, onLoginModalOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#D4A373' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Jazzinator
          </Typography>

          {/* Menu Icon for Mobile */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuItem onClick={handleMenuClose}>Home</MenuItem>
            <MenuItem onClick={handleMenuClose}>Features</MenuItem>
            <MenuItem onClick={handleMenuClose}>Pricing</MenuItem>
            {!userEmail ? (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onLoginModalOpen(); // Apri il modal di login
                }}
              >
                Login
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onLogout(); // Esegui il logout
                }}
              >
                Logout
              </MenuItem>
            )}
          </Menu>

          {/* Desktop Navigation */}
          <div style={{ display: 'flex', alignItems: 'center' }}>

            {!userEmail ? (
              <>
                <Button color="inherit" sx={{ fontWeight: 'bold', marginRight: 2 }}>
                  Home
                </Button>
                <Button color="inherit" sx={{ fontWeight: 'bold', marginRight: 2 }}>
                  Features
                </Button>
                <Button color="inherit" sx={{ fontWeight: 'bold', marginRight: 2 }}>
                  Pricing
                </Button>


                <Button
                  color="inherit"
                  sx={{ fontWeight: 'bold' }}
                  onClick={onLoginModalOpen}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  sx={{ marginRight: 2, fontWeight: 'bold', color: 'inherit' }}
                >
                  Welcome, {userEmail}
                </Typography>
                <Button
                  color="inherit"
                  sx={{ fontWeight: 'bold' }}
                  onClick={onLogout}
                >
                  Logout
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import { Box, Typography, Slider, IconButton, Tooltip } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import HeadsetIcon from "@mui/icons-material/Headset";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";

const TrackControls = ({ track }) => {
  const [volume, setVolume] = useState(track.volume || 1);
  const [muted, setMuted] = useState(track.muted || false);
  const [solo, setSolo] = useState(track.solo || false);

  useEffect(() => {
    track.setVolume(volume);
  }, [volume, track]);

  useEffect(() => {
    track.toggleMute();
  }, [muted, track]);

  useEffect(() => {
    track.toggleSolo();
  }, [solo, track]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        backgroundColor: "#f5f5f5",
        padding: 0,
        borderRadius: 2,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        width: "380px"
      }}
    >
      {/* Nome della traccia */}
      <Typography variant="body1" sx={{ flex: "1 0 auto", fontWeight: "bold",width:"180px" }}>
        {track.name}
      </Typography>

      {/* Controllo del volume */}
      <Box sx={{ display: "flex", alignItems: "center", flex: "2 0 auto" }}>
        <Tooltip title="Volume">
          <VolumeUpIcon />
        </Tooltip>
        <Slider
          value={volume}
          onChange={(e, newValue) => setVolume(newValue)}
          step={0.01}
          min={0}
          max={1}
          sx={{ marginLeft: 2, marginRight: 2 }}
        />
      </Box>

      {/* Pulsante Mute */}
      <Tooltip title={muted ? "Unmute" : "Mute"}>
        <IconButton onClick={() => setMuted((prev) => !prev)} color={muted ? "error" : "default"}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Tooltip>

      {/* Pulsante Solo */}
      <Tooltip title={solo ? "Solo Off" : "Solo"}>
        <IconButton onClick={() => setSolo((prev) => !prev)} color={solo ? "primary" : "default"}>
          {solo ? <HeadsetIcon /> : <HeadsetOffIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TrackControls;

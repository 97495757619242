import React, { useEffect, useRef, useState } from "react";

// BufferLoader class definition
class BufferLoader {
  constructor(context, urlList, callback, callbackDraw) {
    this.context = context;
    this.urlList = urlList;
    this.onload = callback;
    this.bufferList = [];
    this.loadCount = 0;
    this.drawSample = callbackDraw;
  }

  loadBuffer(url, index) {
    console.log("Loading and decoding file: " + url);

    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer";

    const loader = this;

    request.onload = function () {
      loader.context.decodeAudioData(
        request.response,
        function (buffer) {
          console.log(
            `Loaded and decoded track ${loader.loadCount + 1}/${loader.urlList.length}`
          );

          if (!buffer) {
            console.error("Error decoding file data: " + url);
            return;
          }

          loader.bufferList[index] = buffer;
          loader.drawSample(buffer, index);

          if (++loader.loadCount === loader.urlList.length) loader.onload(loader.bufferList);
        },
        function (error) {
          console.error("Error decoding audio data", error);
        }
      );
    };

    request.onprogress = function (e) {
      if (e.total > 0) {
        const progress = document.querySelector("#progress" + index);
        if (progress) {
          progress.value = e.loaded;
          progress.max = e.total;
        }
      }
    };

    request.onerror = function () {
      console.error("BufferLoader: XHR error");
    };

    request.send();
  }

  load() {
    this.bufferList = [];
    this.loadCount = 0;
    console.log("Loading tracks... please wait.");
    for (let i = 0; i < this.urlList.length; ++i) {
      this.loadBuffer(this.urlList[i], i);
    }
  }
}

// Function to add range listeners
function addRangeListeners() {
  console.log("Added range listeners");

  // Display value next to the range input
  document.body.addEventListener("input", (e) => {
    if (e.target.matches("input.display-value")) {
      const value = e.target.value;
      const nextSibling = e.target.nextElementSibling;
      if (nextSibling) {
        nextSibling.value = value;
      }
    }
  });

  // Update the range background dynamically
  document.body.addEventListener("input", (e) => {
    if (e.target.matches('input[type="range"]')) {
      const range = e.target;
      const val =
        ((range.value - range.min) / (range.max - range.min)) * 100;
      const cssProp = `linear-gradient(to right, lightGreen, blue ${val}%, red)`;
      range.style.backgroundImage = cssProp;
    }
  });
}

// Wave component definition
const Wave = ({ track }) => {
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const drawSample = (buffer) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;

      const peaks = getPeaks(buffer, width);
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "maroon";
      peaks.forEach((peak, x) => {
        ctx.fillRect(x, height / 2 - peak, 1, peak * 2);
      });

      setLoading(false);
    };

    const bufferLoader = new BufferLoader(
      audioContext,
      [track.url],
      () => console.log("All tracks loaded"),
      drawSample
    );

    bufferLoader.load();
    addRangeListeners(); // Call to add range listeners
  }, [track]);

  const getPeaks = (buffer, width) => {
    const channelData = buffer.getChannelData(0);
    const blockSize = Math.floor(channelData.length / width);
    const peaks = [];
    for (let i = 0; i < width; i++) {
      const blockStart = i * blockSize;
      const blockEnd = blockStart + blockSize;
      let max = 0;
      for (let j = blockStart; j < blockEnd; j++) {
        max = Math.max(max, Math.abs(channelData[j]));
      }
      peaks.push(max * 100); // Scala per adattarsi al canvas
    }
    return peaks;
  };

  return (
    <div style={{ position: "relative", width: "500px", height: "50px", left:"0px"}}>
      <canvas
        ref={canvasRef}
        width={500}
        height={50}
        style={{ border: "1px solid #ddd", display: "block" }}
      />
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: 0,
            color: "maroon",
            fontWeight: "bold",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          Loading waveform...
        </p>
      )}
    </div>
  );
  
};

export default Wave;

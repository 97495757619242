import React, { useEffect, useState, useRef } from "react";
import TrackControls from "./TrackControls";
import Wave from "./Wave";
import Song from "./Song";
import Track from "./Track";

const Multitrack = ({ selectedSong, masterVolume, onSongLoaded, progress }) => {
  const [currentSong, setCurrentSong] = useState(null);
  const [tracks, setTracks] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    if (selectedSong) {
      loadSong(selectedSong, audioContext);
    }

    return () => {
      audioContext.close();
    };
  }, [selectedSong]);

 

  const loadSong = async (songName, audioContext) => {
    try {
      const response = await fetch(`https://www.jazzinator.com/api/track/${songName}`);
      const songData = await response.json();

      if (!songData || !songData.instruments || songData.instruments.length === 0) {
        console.error("No instruments found for the song.");
        alert("Error: This song doesn't have any instruments or the data is missing.");
        return;
      }

      const song = new Song(songData.id, audioContext);
      setCurrentSong(song);

      if (onSongLoaded) {
        onSongLoaded(song);
      }

      const trackObjects = songData.instruments.map(
        (instrument) => new Track(songData.id, instrument)
      );

      setTracks(trackObjects);
    } catch (error) {
      console.error("Error loading song:", error);
      alert("Failed to load song. Please try again later.");
    }
  };

  const getLinePosition = () => {
    if (!containerRef.current || progress < 0 || progress > 100) return 0;
    const containerWidth = 500;
    return (progress / 100) * containerWidth+380+5; // Calcola la posizione in base al progresso
  };


  const handleVolumeChange = (trackName, newVolume) => {
    setTracks((prevTracks) =>
      prevTracks.map((track) =>
        track.name === trackName ? { ...track, volume: newVolume } : track
      )
    );
  };

  const handleMuteToggle = (trackName, isMuted) => {
    setTracks((prevTracks) =>
      prevTracks.map((track) =>
        track.name === trackName ? { ...track, muted: isMuted } : track
      )
    );
  };

  const handleSoloToggle = (trackName, isSolo) => {
    setTracks((prevTracks) =>
      prevTracks.map((track) => {
        if (track.name === trackName) {
          return { ...track, solo: isSolo };
        }
        return { ...track, muted: isSolo }; // Silenzia le altre tracce se una è in solo
      })
    );
  };



  return (
    <div
      id="soundContainer"
      ref={containerRef}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "0px",
        border: "1px solid #ddd",
        backgroundColor: "#ffffff", // Sfondo nero
        padding: "10px",
      }}
    >
      {/* Linea verticale per il progresso */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: `${getLinePosition()}px`,
          width: "2px",
          height: "100%",
          backgroundColor: "limegreen",
          zIndex: 10,
        }}
      />
      {tracks.map((track, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
            borderBottom: "1px solid #555",
            padding: "5px 0",
          }}
        >
          <div style={{ flex: "1" }}>
            <TrackControls track={track} />
          </div>
          <div style={{ flex: "3" }}>
            <Wave track={track} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Multitrack;

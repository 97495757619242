import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import RegisterModal from './components/RegisterModal';
import LoginModal from './components/LoginModal';
import Dashboard from './components/Dashboard'; // Importa il componente Dashboard

// Componente per proteggere le rotte
function PrivateRoute({ children, isAuthenticated }) {
  return isAuthenticated ? children : <Navigate to="/" />;
}

function App() {
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const handleRegister = async ({ email, password, firstName, lastName }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, firstName, lastName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
      }

      const data = await response.json();
      console.log('Registration successful:', data);
    } catch (err) {
      console.error('Error during registration:', err.message);
      throw err;
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
        console.log('API URL:', process.env.REACT_APP_API_URL);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Login failed');
        }

        const data = await response.json();

        // Salva il token JWT nel local storage
        localStorage.setItem('jwtToken', data.token);

        setUserEmail(data.user.email); // Aggiorna lo stato con l'email dell'utente
        console.log('Login successful:', data);

        setLoginModalOpen(false); // Chiudi il modal di login
    } catch (err) {
        console.error('Error during login:', err.message);
        throw err;
    }
};


const handleLogout = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
          method: 'POST',
          credentials: 'include',
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Logout failed');
      }

      // Rimuovi il token dal local storage
      localStorage.removeItem('jwtToken');

      setUserEmail(null); // Resetta l'email dell'utente
      console.log('User logged out successfully');
  } catch (err) {
      console.error('Error during logout:', err.message);
  }
};

const handleGoogleLogin = async (credentialResponse) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google-login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: credentialResponse.credential }),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Google login failed');
      }

      const data = await response.json();

      // Salva il token JWT nel local storage
      localStorage.setItem('jwtToken', data.token);

      setUserEmail(data.user.email); // Aggiorna lo stato con l'email dell'utente
      console.log('Google login successful:', data);

      setLoginModalOpen(false); // Chiudi il modal di login
  } catch (err) {
      console.error('Error during Google login:', err.message);
  }
};



  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
        try {
            // Decodifica il token (opzionale) per ottenere informazioni sull'utente
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            if (decodedToken.exp * 1000 > Date.now()) {
                setUserEmail(decodedToken.email);
            } else {
                localStorage.removeItem('jwtToken'); // Token scaduto, rimuovilo
            }
        } catch (err) {
            console.error('Error decoding token:', err.message);
            localStorage.removeItem('jwtToken');
        }
    }
}, []);



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header
          userEmail={userEmail}
          onLogout={handleLogout}
          onLoginModalOpen={() => setLoginModalOpen(true)}
        />

        <Routes>
          {/* Rotta per la dashboard protetta */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAuthenticated={!!userEmail}>
                <Dashboard />
              </PrivateRoute>
            }
          />

          {/* Rotta per la home page */}
          <Route
            path="/"
            element={
              userEmail ? (
                <Navigate to="/dashboard" />
              ) : (
                <>
                  <HeroSection />
                  <Features />
                  <Pricing />
                  <Footer />
                  <RegisterModal
                    open={isRegisterModalOpen}
                    handleClose={() => setRegisterModalOpen(false)}
                    onSubmit={handleRegister}
                  />
                  <LoginModal
                    open={isLoginModalOpen}
                    handleClose={() => setLoginModalOpen(false)}
                    onSubmit={handleLogin}
                    onGoogleLogin={handleGoogleLogin}
                  />
                </>
              )
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { IconButton, Slider, Stack, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import LoopIcon from "@mui/icons-material/Loop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const ControlPanel = ({
    onPlay,
    onPause,
    onStop,
    onLoopStart,
    onLoopEnd,
    onLoopReset,
    toggleLoop,
    isLoopActive,
    volume,
    setVolume,
    isPlaying,
    isSongLoaded,
    isSongInitialized,
  }) => {
  
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
           {!isSongInitialized  && (
         <Tooltip title={isPlaying ? "" : "Play"}>
            <span>
              <IconButton
                color="primary"
                onClick={onPlay}
                disabled={!isSongLoaded}
              >
                {isPlaying ? "" : <PlayArrowIcon />}
              
              </IconButton>
            </span>
          </Tooltip>
)}
 
 
          {isSongInitialized && (
         <Tooltip title={isPlaying ? "Pause" : "Resume"}>
            <span>
              <IconButton
                color="primary"
                onClick={onPause}
                disabled={!isSongLoaded}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              
              </IconButton>
            </span>
          </Tooltip>
          )}
  
        <Tooltip title="Stop">
          <span>
            <IconButton
              color="error"
              onClick={onStop}
              disabled={!isSongLoaded || !isPlaying}
            >
              <StopIcon />
            </IconButton>
          </span>
        </Tooltip>
  
        <Tooltip title="Set Loop Start">
          <span>
            <IconButton
              color="info"
              onClick={onLoopStart}
              disabled={!isSongLoaded}
            >
              <SkipPreviousIcon />
            </IconButton>
          </span>
        </Tooltip>
  
        <Tooltip title="Set Loop End">
          <span>
            <IconButton
              color="info"
              onClick={onLoopEnd}
              disabled={!isSongLoaded}
            >
              <SkipNextIcon />
            </IconButton>
          </span>
        </Tooltip>
  
        <Tooltip title="Reset Loop">
          <span>
            <IconButton
              color="warning"
              onClick={onLoopReset}
              disabled={!isSongLoaded}
            >
              <RestartAltIcon />
            </IconButton>
          </span>
        </Tooltip>
  
        <Tooltip title={isLoopActive ? "Disable Loop" : "Enable Loop"}>
          <span>
            <IconButton
              color={isLoopActive ? "success" : "default"}
              onClick={toggleLoop}
            >
              <LoopIcon />
            </IconButton>
          </span>
        </Tooltip>
  
        <Tooltip title="Master Volume">
          <Slider
            value={volume}
            disabled={!isSongLoaded}
            onChange={(e, newValue) => setVolume(newValue)}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            sx={{ width: 150 }}
          />
        </Tooltip>
      </Stack>
    );
  };
  

export default ControlPanel;

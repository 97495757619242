import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";

const SongSelect = ({ onSelectSong }) => {
  const [songList, setSongList] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);

  useEffect(() => {
    const loadSongList = async () => {
      try {
        const response = await fetch("https://www.jazzinator.com/api/track");
        const songList = await response.json();
        setSongList(songList);
      } catch (error) {
        console.error("Error fetching song list:", error);
      }
    };

    loadSongList();
  }, []);

  const handleSongChange = (event, value) => {
    setSelectedSong(value);
    if (value) {
      onSelectSong(value);
    }
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Autocomplete
        options={songList}
        value={selectedSong}
        onChange={handleSongChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a Song"
            variant="outlined"
            fullWidth
          />
        )}
        noOptionsText="No songs available"
      />
    </Box>
  );
};

export default SongSelect;

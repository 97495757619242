import React, { useState, useEffect } from "react";
import { Paper, Box } from "@mui/material";
import ControlPanel from "./ControlPanel";
import SongSelect from "./SongSelect";
import Multitrack from "./Multitrack";
import Song from "./Song";

const Dashboard = () => {
  const [song, setSong] = useState(null); // Variabile unificata per la canzone selezionata e corrente
  const [volume, setVolume] = useState(100);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSongLoaded, setIsSongLoaded] = useState(false);
  const [isSongInitialized, setIsSongInitialized] = useState(false);
  const [progress, setProgress] = useState(0);
  const initializeAndLoadSong = async (songName) => {
    console.log("Loading and initializing the song...");
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
    try {
      const response = await fetch(`https://www.jazzinator.com/api/track/${songName}`);
      const songData = await response.json();
  
      if (!songData || !songData.instruments || songData.instruments.length === 0) {
        console.error("No instruments found for the song.");
        alert("Error: This song doesn't have any instruments or the data is missing.");
        return null;
      }
  
      const initializedSong = new Song(songData.id, audioContext);
      songData.instruments.forEach((instrument) => initializedSong.addTrack(instrument));
  
      const urls = initializedSong.getUrlsOfTracks();
      const buffers = await Promise.all(
        urls.map(async (url) => {
          const response = await fetch(url);
          const arrayBuffer = await response.arrayBuffer();
          return audioContext.decodeAudioData(arrayBuffer);
        })
      );
  
      initializedSong.setDecodedAudioBuffers(buffers);
      initializedSong.setVolume(volume / 100);
      initializedSong.initialized = true;
      
      return initializedSong; // Ritorna un'istanza valida di Song
    } catch (error) {
      console.error("Error loading and initializing song:", error);
      return null;
    }
  };
  

  const handlePlay = async () => {
    if (!song) {
      console.log("No song selected to play.");
      return;
    }
  
    if (!song.initialized) {
      const loadedSong = await initializeAndLoadSong(song.name);
      if (loadedSong) {
        setSong(loadedSong);
        setIsSongInitialized(true);
        loadedSong.play(0);
        setIsPlaying(true);
        startProgressUpdate(loadedSong);
      }
    } else {
      // Se il brano è già caricato, riproduci
      song.play(0);
      // Aggiorna lo stato di `song` per riflettere la modifica
            setIsPlaying(true);
            startProgressUpdate(song);
    }
  };

  const startProgressUpdate = (currentSong) => {
    const currentTime = currentSong.audioContext.currentTime;
    const duration = currentSong.getDuration();
    if (duration > 0) {
      setProgress((currentTime / duration) * 100); // Calcola il progresso in percentuale
    }
    const interval = setInterval(() => {
      const currentTime = currentSong.audioContext.currentTime;
      const duration = currentSong.getDuration();
      if (duration > 0) {
        setProgress((currentTime / duration) * 100); // Calcola il progresso in percentuale
      }
    }, 100);
  
    // Salva l'intervallo per poterlo cancellare quando la canzone si ferma
    currentSong.progressInterval = interval;
  };

  const handlePause = () => {


    if (song) {
      console.log("Pausa");


      
      if (song.progressInterval) {
        if(!song.paused){
          song.pause();
          console.log("Clear");
        clearInterval(song.progressInterval);
        song.progressInterval = null; // Reset dell'intervallo per evitare errori futuri
        }
      }else{
            song.pause();
            console.log("Resume");
            startProgressUpdate(song);


      }
      
      // Aggiorna lo stato di `song` per riflettere la modifica
      setIsPlaying(!song.paused);
    }
  };

  const handleStop = () => {
    if (song) {
      song.stop();
      if (song.progressInterval) {
        clearInterval(song.progressInterval);
      }
      setSong((prev) => ({ ...prev, initialized: false })); 
      setIsPlaying(false);
      setIsSongInitialized(false);
      setProgress(0); // Resetta il progresso
    }
  };

  const handleSongSelect = (songName) => {
    if(isPlaying)  {  
      handleStop();
      setIsSongInitialized(false);
      setIsPlaying(false);
    }
    setSong({ name: songName, initialized: false }); // Imposta solo il nome della canzone inizialmente
      initializeAndLoadSong(songName);
  };

  const onSongLoaded = (loadedSong) => {
    if (!(loadedSong instanceof Song)) {
      console.error("Loaded song is not an instance of Song.");
      return;
    }
    console.log("Song fully loaded:", loadedSong.name);
    setSong(loadedSong); // Aggiorna direttamente la canzone con l'istanza corretta
    setIsSongLoaded(true);
  };

  useEffect(() => {
    if (song && song.initialized) {
      song.setVolume(volume / 100);
    }
  }, [volume, song]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          width: "100%",
          maxWidth: "1200px",
          borderRadius: "15px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box sx={{ textAlign: "center", marginBottom: 2 }}>
        
        </Box>
        <SongSelect onSelectSong={handleSongSelect} />
        <ControlPanel
          onPlay={handlePlay}
          onPause={handlePause}
          onStop={handleStop}
          volume={volume}
          setVolume={setVolume}
          isPlaying={isPlaying}
          isSongLoaded={isSongLoaded}
          isSongInitialized={isSongInitialized}
        />
        <Multitrack
          selectedSong={song && song.name}
          masterVolume={volume}
          onSongLoaded={onSongLoaded}
          progress={progress} // Passa il progresso
        />
      </Paper>
    </Box>
  );
};

export default Dashboard;

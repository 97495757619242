import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 450,
  bgcolor: '#FBEED7', // Colore legno chiaro
  borderRadius: '15px',
  boxShadow: 24,
  p: 4,
  border: '2px solid #D4A373', // Colore marrone chiaro per il bordo
};

const RegisterModal = ({ open, handleClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await onSubmit({
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });
      setSuccess('Registration successful! You can now log in.');
      setError('');
      setFormData({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
      });
    } catch (err) {
      setError(err.message || 'Registration failed');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="register-modal"
      closeAfterTransition
    >
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography id="register-modal" variant="h5" fontWeight="bold" color="#A55B27">
            Create Your Account
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#A55B27' }} />
          </IconButton>
        </Box>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            InputLabelProps={{
              style: { color: '#A55B27' },
            }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            InputLabelProps={{
              style: { color: '#A55B27' },
            }}
          />
          <TextField
            label="Email Address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            InputLabelProps={{
              style: { color: '#A55B27' },
            }}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            InputLabelProps={{
              style: { color: '#A55B27' },
            }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            fullWidth
            required
            variant="outlined"
            InputLabelProps={{
              style: { color: '#A55B27' },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              background: '#D4A373',
              color: '#fff',
              fontWeight: 'bold',
              textTransform: 'none',
              '&:hover': {
                background: '#A55B27',
              },
            }}
          >
            Register Now
          </Button>
        </Box>

        <Divider sx={{ my: 3, borderColor: '#D4A373' }} />

        <Typography variant="body2" textAlign="center" color="textSecondary">
          Already have an account?{' '}
          <a
            href="/login"
            style={{
              color: '#A55B27',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Log in here
          </a>.
        </Typography>
      </Box>
    </Modal>
  );
};

export default RegisterModal;

import React from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';

function Pricing() {
  const plans = [
    {
      name: 'Basic',
      price: '$9.99/month',
      features: ['Access to all lessons', 'Basic MIDI files', 'Email support'],
      image: '/images/pricing-basic.webp',
    },
    {
      name: 'Pro',
      price: '$19.99/month',
      features: ['Advanced exercises', 'Full MIDI library', 'Community collaboration'],
      image: '/images/pricing-pro.webp',
    },
    {
      name: 'Premium',
      price: '$29.99/month',
      features: ['1-on-1 coaching', 'Exclusive content', 'All Pro features'],
      image: '/images/pricing-premium.webp',
    },
  ];

  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#EEE3CB' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '30px' }}>
        Choose Your Plan
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                textAlign: 'center',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }}
            >
              <CardContent>
                <Box
                  component="img"
                  src={plan.image}
                  alt={`${plan.name} plan`}
                  sx={{
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
                  {plan.name}
                </Typography>
                <Typography variant="h4" sx={{ margin: '10px 0' }}>
                  {plan.price}
                </Typography>
                <ul>
                  {plan.features.map((feature, idx) => (
                    <li key={idx} style={{ marginBottom: '10px' }}>
                      {feature}
                    </li>
                  ))}
                </ul>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#D4A373',
                    color: '#fff',
                    marginTop: '20px',
                  }}
                >
                  Subscribe
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Pricing;

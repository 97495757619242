import React, { useState } from 'react';
import { Button, Box, Typography, keyframes } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RegisterModal from './RegisterModal'; // Importa il modal di registrazione

// Animazioni
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const gradientBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: "'Playfair Display', serif",
    h3: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
  },
});

function HeroSection() {
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);

  const handleRegister = async ({ email, password, firstName, lastName }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, firstName, lastName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
      }

      const data = await response.json();
      console.log('Registration successful:', data);
      setRegisterModalOpen(false); // Chiudi il modal dopo il successo
    } catch (err) {
      console.error('Error during registration:', err.message);
      throw err;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url('/images/hero.webp')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '20px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Overlay con animazione gradiente */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(-45deg, rgba(212, 163, 115, 0.7), rgba(0, 0, 0, 0.7))',
            backgroundSize: '200% 200%',
            animation: `${gradientBackground} 10s ease infinite`,
            zIndex: 1,
          }}
        />

        {/* Contenuto */}
        <Box
          sx={{
            zIndex: 2,
            animation: `${fadeIn} 1s ease-out`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 'bold',
              marginBottom: '20px',
              textShadow: '2px 2px 6px rgba(0, 0, 0, 0.8)',
              animation: `${fadeIn} 1s ease-out`,
            }}
          >
            Jazzinator
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginBottom: '20px',
              textShadow: '1px 1px 4px rgba(0, 0, 0, 0.6)',
              animation: `${fadeIn} 1.2s ease-out`,
            }}
          >
            Unlock the Spirit of Jazz with Personalized Lessons and Collaboration
          </Typography>
          <Button
            variant="contained"
            onClick={() => setRegisterModalOpen(true)} // Apri il modal
            sx={{
              backgroundImage: 'linear-gradient(90deg, #D4A373, #b6885c)',
              color: '#000',
              padding: '12px 24px',
              fontSize: '1.2em',
              fontWeight: 'bold',
              borderRadius: '30px',
              border: '2px solid rgba(255, 255, 255, 0.8)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              boxShadow: '0 8px 15px rgba(0, 0, 0, 0.3)',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
                backgroundImage: 'linear-gradient(90deg, #b6885c, #8a5e38)',
              },
            }}
          >
            Start for Free
          </Button>
        </Box>

        {/* Modal di registrazione */}
        <RegisterModal
          open={isRegisterModalOpen}
          handleClose={() => setRegisterModalOpen(false)}
          onSubmit={handleRegister}
        />
      </Box>
    </ThemeProvider>
  );
}

export default HeroSection;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Cormorant Garamond', serif", // Font principale
    h3: {
      fontFamily: "'Great Vibes', cursive", // Font per i titoli
      fontSize: '3rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none', // Disabilita lettere maiuscole nei bottoni
    },
  },
  palette: {
    primary: {
      main: '#D4A373', // Colore principale del tema
    },
    secondary: {
      main: '#6C757D', // Colore secondario
    },
  },
});

export default theme;

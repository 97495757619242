import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

function Features() {
  const features = [
    {
      title: 'Free Jazz Exercises',
      description: '4 exercises to kickstart your journey into jazz.',
      image: 'images/feature-jazz-exercises.webp', // Sostituisci
    },
    {
      title: 'MIDI Files',
      description: 'Downloadable MIDI files for all exercises.',
      image: 'images/feature-midi.webp',
    },
    {
      title: 'Collaboration',
      description: 'Collaborate with other musicians and learn together.',
      image: 'images/feature-collaboration.webp',
    },
    {
      title: 'Exclusive Content',
      description: 'Access step-by-step methods and advanced exercises.',
      image: 'images/feature-exclusive-content.webp',
    },
  ];

  return (
    <Box sx={{ padding: '40px 20px' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '30px' }}>
        Why Choose Jazzinator?
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#F5F0E1',
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <img
                src={feature.image}
                alt={feature.title}
                style={{ width: '100px', marginRight: '20px' }}
              />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {feature.title}
                </Typography>
                <Typography>{feature.description}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Features;
